import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { ContractedServiceDetailsRepository } from 'modules/contractedService/domain/management/tracking/detail/repository/ContractedServiceDetailsRepository'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/services/contracted/tracking/detail`

export const HttpContractedServiceDetailsRepository = (authData: IAuthData): ContractedServiceDetailsRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findOne: async (serviceId: string, options) =>
			await FetchAPI.get(`${url}/${serviceId}`, { ...fetchOptions, ...options }),
	}
}
