import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loading, UfinetSectionBox } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { ContractedServiceTrackingDetail } from 'modules/contractedService/domain/detail/ContractedServicesTrackingDetail'
import { ContractedServiceDetailsRepository } from 'modules/contractedService/domain/management/tracking/detail/repository/ContractedServiceDetailsRepository'
import { ContractedServiceDetailsType } from 'modules/contractedService/domain/type/contractedServiceDetails/ContractedServiceDetailsType'

type Props = {
	contractedServiceDetailsRepository: ContractedServiceDetailsRepository
}

const ContractedServiceDetails: FC<Props> = ({ contractedServiceDetailsRepository }) => {
	const { id } = useParams<{ id: string }>()
	const [serviceDetails, setServiceDetails] = useState<ContractedServiceTrackingDetail | null>(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<string | null>(null)
	const [activeTab, setActiveTab] = useState(ContractedServiceDetailsType.GENERAL)
	const translate = useTranslator()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await contractedServiceDetailsRepository.findOne(id!)
				setServiceDetails(data)
				setError(null)
			} catch (err) {
				setError(translate('SERVICE.DETAIL.ERROR_FETCH'))
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [id, contractedServiceDetailsRepository, translate])

	if (loading) {
		return (
			<div className="position-relative d-flex flex-fill m-10">
				<Loading />
			</div>
		)
	}

	if (error) {
		return (
			<UfinetSectionBox>
				<p>{error}</p>
			</UfinetSectionBox>
		)
	}

	if (!serviceDetails) {
		return (
			<UfinetSectionBox>
				<p>{translate('SERVICE.DETAIL.NO_DATA')}</p>
			</UfinetSectionBox>
		)
	}

	const renderActiveTab = () => {
		switch (activeTab) {
			case ContractedServiceDetailsType.GENERAL:
				return (
					<div className="active mx-3">
						<h2 className="my-5">{translate('SERVICE.DETAIL.GENERAL')}</h2>
						<p>
							{translate('SERVICE.NAME')}: {serviceDetails.serviceName}
						</p>
						<p>
							{translate('SERVICE.CODE')}: {serviceDetails.serviceCode}
						</p>
						<p>
							{translate('SERVICE.LOCATION')}:{' '}
							{[serviceDetails.countryOrigin, serviceDetails.countryDestination].filter(Boolean).join(' - ')}
						</p>
						<p>
							{translate('SERVICE.CLIENT')}: {serviceDetails.clientName}
						</p>
						<p>
							{translate('SERVICE.TERM')}: {serviceDetails.deadline}
						</p>
					</div>
				)
			case ContractedServiceDetailsType.AMOUNTS:
				return (
					<div className="active mx-3">
						<h2 className="my-5">{translate('SERVICE.DETAIL.AMOUNTS')}</h2>
						<p>
							{translate('SERVICE.NRC')}: {serviceDetails.nrc}
						</p>
						<p>
							{translate('SERVICE.MRC')}: {serviceDetails.mrc}
						</p>
					</div>
				)
			case ContractedServiceDetailsType.ATTRIBUTES:
				return (
					<div className="active mx-3">
						<h2 className="my-5">{translate('SERVICE.DETAIL.ATTRIBUTES')}</h2>
						{serviceDetails.capacityGroup && (
							<>
								<p>
									{translate('SERVICE.ATTRIBUTES.BANDWIDTH')}: {serviceDetails.capacityGroup.bandwidth}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.TECHNOLOGY')}: {serviceDetails.capacityGroup.capacityTechnology}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.TRANSMISSION_MEAN')}: {serviceDetails.capacityGroup.transmissionMean}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.REDUNDANCY_TYPE')}: {serviceDetails.capacityGroup.redundancyType}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.TRANSPORT_LAYER')}:{' '}
									{serviceDetails.capacityGroup.capacityTransportLayer}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.STANDARD_TYPE')}: {serviceDetails.capacityGroup.capacityStandardType}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.STANDARD_SUBTYPE')}:{' '}
									{serviceDetails.capacityGroup.capacityStandardSubtype}
								</p>
							</>
						)}
						{serviceDetails.internetGroup && (
							<>
								<p>
									{translate('SERVICE.ATTRIBUTES.BANDWIDTH')}: {serviceDetails.internetGroup.bandwidth}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.INTERNET_ACCESS_TYPE')}:{' '}
									{serviceDetails.internetGroup.internetAccessType}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.TRANSMISSION_MEAN')}: {serviceDetails.internetGroup.transmissionMean}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.OVER_SUBSCRIPTION')}: {serviceDetails.internetGroup.overSubscription}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.PUBLIC_IP_TYPE')}: {serviceDetails.internetGroup.internetPublicIpType}
								</p>
								<p>
									{translate('SERVICE.ATTRIBUTES.PUBLIC_IP_NUMBER')}: {serviceDetails.internetGroup.publicIpsNumber}
								</p>
							</>
						)}
					</div>
				)
			default:
				return null
		}
	}

	return (
		<UfinetSectionBox>
			<div className="row justify-content-between align-items-end mb-5">
				<div className="card">
					<ul
						className="nav nav-tabs"
						role="tablist"
					>
						<li className="nav-item mb-5">
							<button
								className={`nav-link ${activeTab === ContractedServiceDetailsType.GENERAL ? 'active' : ''}`}
								onClick={() => setActiveTab(ContractedServiceDetailsType.GENERAL)}
							>
								{translate('SERVICE.DETAIL.GENERAL')}
							</button>
						</li>
						<li className="nav-item">
							<button
								className={`nav-link ${activeTab === ContractedServiceDetailsType.AMOUNTS ? 'active' : ''}`}
								onClick={() => setActiveTab(ContractedServiceDetailsType.AMOUNTS)}
							>
								{translate('SERVICE.DETAIL.AMOUNTS')}
							</button>
						</li>
						<li className="nav-item">
							<button
								className={`nav-link ${activeTab === ContractedServiceDetailsType.ATTRIBUTES ? 'active' : ''}`}
								onClick={() => setActiveTab(ContractedServiceDetailsType.ATTRIBUTES)}
							>
								{translate('SERVICE.DETAIL.ATTRIBUTES')}
							</button>
						</li>
					</ul>
					{renderActiveTab()}
				</div>
			</div>
		</UfinetSectionBox>
	)
}

export { ContractedServiceDetails }
