import { ContractedServiceDetails } from 'components/services/management/tracking/detail/ContractedServiceDetails'
import { FC, useContext, useMemo } from 'react'
import { UfinetBox } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'
import { HttpContractedServiceDetailsRepository } from 'modules/contractedService/infrastructure/management/details/HttpContractedServiceDetailsRepository'

const ContractedServiceDetailsPage: FC<{}> = () => {
	const authData = useContext(AuthContext)

	const contractedServiceDetailsRepository = useMemo(() => {
		return HttpContractedServiceDetailsRepository(authData)
	}, [authData])

	return (
		<UfinetBox>
			<ContractedServiceDetails contractedServiceDetailsRepository={contractedServiceDetailsRepository} />
		</UfinetBox>
	)
}
export { ContractedServiceDetailsPage }
