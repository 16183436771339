export enum PetitionTrackingStatus {
	PROGRAMADO = 1,
	NO_INICIADO = 2,
	EN_PLANIFICACION = 3,
	EN_CONTRUCCION = 4,
	AGENDADAS = 5,
	PENDIENTE_CLIENTE = 6,
	PENDIENTE_COMERCIAL = 7,
	DETENIDAS = 8,
	DADO_DE_BAJA = 9,
	SUSPENDIDO = 10,
	BAJA_LOGICA = 11,
	COMPLETADA = 12,
	CANCELADO = 13,
}
