import { faBook, faCancel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useBeforeCancelTrackingModal } from 'components/services/management/tracking/hooks/useBeforeCancelTrackingModal'
import dayjs from 'dayjs'
import { PetitionTrackingCancelRequest } from 'modules/contractedService/application/management/tracking/cancel/dto/PetitionTrackingCancelRequest'

import { PetitionTracking } from 'modules/contractedService/domain/management/tracking/PetitionTracking'
import { PetitionTrackingStatus } from 'modules/contractedService/domain/management/tracking/PetitionTrackingStatus'
import { Link, useNavigate } from 'react-router-dom'
import { PATH_CONTRACTED_SERVICE_DETAILS, PATH_EVENTS } from 'routing/protected/PrivateRoutes'
import { ColData, TooltipButton } from 'ufinet-web-components'
import { getEnumKeyFromEnumValue, noContentPlaceholder, useTranslator } from 'ufinet-web-functions'

type HookInput = {
	petitionTrackings: PetitionTracking[]
	actions: {
		cancelOperations: (petitionTrackingCancelRequest: PetitionTrackingCancelRequest) => void
	}
}

type HookOutput = {
	columns: ColData[]
}
const usePetitionTrackingColsData: (input: HookInput) => HookOutput = ({
	petitionTrackings,
	actions: { cancelOperations },
}) => {
	const translate = useTranslator()
	const filterTrackingStates = [...new Set(petitionTrackings.map((it) => it.trackingStatus))].sort()
	const { fireBeforeCancelTrackingModal } = useBeforeCancelTrackingModal({ cancelTrackingsFn: cancelOperations })
	const tableDateFormat = 'DD/MM/YYYY'
	const navigate = useNavigate()

	const columns: ColData<PetitionTracking>[] = [
		{
			field: 'id',
			header: 'PETITION.TRACKING.REQUEST_ID',
			width: '10%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: false,
		},
		{
			field: 'serviceName',
			header: 'PETITION.TRACKING.SERVICE.DESCRIPTION',
			width: '20%',
			nonFilterable: true,
			nonSortable: true,
			nonSearchable: false,
		},
		{
			field: 'serviceCode',
			header: 'PETITION.TRACKING.REFERENCE',
			width: '11%',
			nonFilterable: true,
			nonSortable: true,
			nonSearchable: false,
			body: (row: PetitionTracking) => (
				<Link
					to={`${PATH_CONTRACTED_SERVICE_DETAILS}/${row.newServiceId}`}
					className="ufinet-table-cell overflow"
				>
					{row.orsCode || row.serviceCode}
				</Link>
			),
		},
		{
			field: 'trackingStatus',
			header: 'PETITION.TRACKING.STATUS',
			width: '10%',
			nonFilterable: false,
			nonSortable: true,
			nonSearchable: true,
			filterOptions: filterTrackingStates.map((filterStatus) => ({
				value: filterStatus,
				label: translate(`PETITION.TRACKING.STATUS.${getEnumKeyFromEnumValue(PetitionTrackingStatus, filterStatus)}`),
			})),
			body: (row: PetitionTracking) => {
				return translate(
					getEnumKeyFromEnumValue(PetitionTrackingStatus, row.trackingStatus)
						? `PETITION.TRACKING.STATUS.${getEnumKeyFromEnumValue(PetitionTrackingStatus, row.trackingStatus)}`
						: 'UNKNOWN'
				)
			},
		},
		{
			field: 'creationDate',
			header: 'PETITION.TRACKING.CREATION_DATE',
			width: '9%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: PetitionTracking) => dayjs(row.creationDate).format(tableDateFormat) || noContentPlaceholder,
		},
		{
			field: 'commitmentDate',
			header: 'PETITION.TRACKING.COMMITMENT.DATE',
			width: '10%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: PetitionTracking) =>
				row?.commitmentDate ? dayjs(row.commitmentDate).format(tableDateFormat) : noContentPlaceholder,
		},
		{
			field: 'expectedDeliveryDate',
			header: 'PETITION.TRACKING.EXPECTED_DELIVERY_DATE',
			width: '10%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: PetitionTracking) =>
				row?.expectedDeliveryDate ? dayjs(row.expectedDeliveryDate).format(tableDateFormat) : noContentPlaceholder,
		},
		{
			field: 'endDate',
			header: 'PETITION.TRACKING.END_DATE',
			width: '10%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: PetitionTracking) =>
				row?.endDate ? dayjs(row.endDate).format(tableDateFormat) : noContentPlaceholder,
		},
		{
			field: 'events',
			header: 'PETITION.TRACKING.EVENTS',
			width: '5%',
			contentDisplayFormat: 'center',
			nonFilterable: true,
			nonSortable: true,
			nonSearchable: true,
			body: (row) => (
				<TooltipButton
					buttonProps={{
						className: 'table-action-button',
						content: (
							<FontAwesomeIcon
								className="fa-solid"
								size="lg"
								icon={faBook}
								color="#0065a4"
								onClick={() => navigate(`${row.newServiceId}/${PATH_EVENTS}`)}
							/>
						),
					}}
				>
					{translate('PETITION.TRACKING.EVENTS.SEE_DETAILS')}
				</TooltipButton>
			),
		},
		{
			field: 'actions',
			header: 'PETITION.TRACKING.ACTIONS',
			width: '5%',
			contentDisplayFormat: 'center',
			nonFilterable: true,
			nonSortable: true,
			nonSearchable: true,
			body: (row) =>
				row.isRemovable ? (
					<TooltipButton
						tooltipProps={{
							styles: { color: row.reversal ? 'var(--bs-form-invalid-color)' : 'inherit' },
						}}
						buttonProps={{
							className: 'table-action-button',
							isDisabled: row.reversal,
							content: (
								<FontAwesomeIcon
									className="fa-solid"
									size="lg"
									icon={faCancel}
									color="#B22222"
									onClick={() => fireBeforeCancelTrackingModal({ ids: [row.id] })}
								/>
							),
						}}
					>
						{translate(row.reversal ? 'PETITION.TRACKING.CANCEL.REVERSAL' : 'PETITION.TRACKING.CANCEL')}
					</TooltipButton>
				) : (
					noContentPlaceholder
				),
		},
	]
	return {
		columns,
	}
}

export { usePetitionTrackingColsData }
